<template>
  <span>
    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="primary"
      dark
    >
      <loading ref="loading"/>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"/>
      <router-link
        :to="{name: 'home'}"
      >
        <div class="d-flex align-center">
          <v-img
            :src="require('../assets/y3-logo.png')"
            :alt="appName"
            class="shrink mr-2"
            contain
            transition="scale-transition"
            width="40"
          />

          <h1 class="white--text shrink hidden-sm-and-down">{{appName}}</h1>

<!--          <v-img-->
<!--            :src="require('./assets/y3-name-dark.png')"-->
<!--            :alt="appName"-->
<!--            class="shrink mt-1 hidden-sm-and-down"-->
<!--            contain-->
<!--            width="40"-->
<!--          />-->
        </div>
      </router-link>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on">
            {{ languages[$i18n.locale] }}
            <v-icon right>mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(language, key) in languages"
            :key="key"
            @click="localeItemClicked(key)"
            rel="noopener" ripple="ripple"
          >
            <v-list-item-title>{{ language }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-spacer></v-spacer>

      <v-btn
        :to="{name: 'home'}"
        exact
        text
      >
        <span class="mr-2">{{$t('home')}}</span>
        <v-icon>mdi-home</v-icon>
      </v-btn>

      <!-- Authenticated -->
      <template v-if="authenticated">

        <template v-if="isCsAgent || isCsAgentBot || isManager || isRoot">
<!--          :to="{name: 'task', params: { status: 'pending' }}"-->
          <v-btn text>
            <span class="mr-2">{{$t('notification')}}</span>
            <v-badge
              :content="notificationCount"
              :value="notificationCount"
              color="green"
              overlap
            >
              <v-icon>mdi-bell</v-icon>
            </v-badge>
          </v-btn>
          <v-switch
            v-if="(isCsAgent || isCsAgentBot) && autoAssignMode"
            v-model="workingMode"
            :label="$t('working_mode')"
            :loading="switchBusy"
            class="working bold-uppercase"
            hide-details
          ></v-switch>
        </template>

        <v-menu 
          offset-y
          :close-on-content-click=false
          v-if="disp_balance"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn 
              text 
              v-bind="attrs" 
              v-on="on"
              @click="refreshUser"
            >
              {{ $t('credit_balance') }}
              <v-icon right>mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list 
            dense 
            disabled
            v-if="disp_balance.filter(x => x.amount > 0).length > 0"
          >
            <v-list-item
              v-for="(balance, key) in disp_balance.filter(x => x.amount > 0)"
              :key="key"
              rel="noopener" ripple="ripple"
            >
              <v-list-item-icon
                :style="{ fontWeight: 'bold' }"
              >
                {{ balance.currency }}
              </v-list-item-icon>
              <v-list-item-title
                :style="{textAlign: 'right'}"
              >
                {{ 
                  balance.amount.toLocaleString(
                    undefined, 
                    { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                  ) 
                }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
          <v-list v-else>
            <v-list-item>0</v-list-item>
          </v-list>
        </v-menu>
        <v-menu :nudge-bottom="10" offset-y origin="center center" transition="scale-transition">
          <template v-slot:activator="{ on }">
            <v-btn text v-on="on">
              <v-icon left>mdi-account</v-icon>
              {{ user.username }}
              <v-icon right>mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list class="pa-0">
            <v-list-item
              :disabled="item.disabled" :key="index"
              @click="accountMenuItemClicked(item.action)"
              rel="noopener" ripple="ripple"
              v-for="(item,index) in account_items"
            >
              <v-list-item-action v-if="item.icon">
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <!-- Guest -->
      <v-btn
        v-else
        :to="{name: 'signin'}"
        text
      >
        <span class="mr-2">{{$t('sign_in')}}</span>
        <v-icon>mdi-login</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
      v-model="drawer"
    >
      <v-list dense>
        <template v-for="item in items">
          <v-row
            :key="item.heading"
            align="center"
            v-if="item.heading"
          >
            <v-col cols="6">
              <v-subheader v-if="item.heading">
                {{ item.heading }}
              </v-subheader>
            </v-col>
            <v-col
              class="text-center"
              cols="6"
            >
              <a
                class="body-2 black--text"
                href="#!"
              >EDIT</a>
            </v-col>
          </v-row>
          <v-list-group
            :key="item.text"
            :prepend-icon="item.model ? item.icon : item['icon-alt']"
            append-icon=""
            v-else-if="item.children"
            v-model="item.model"
          >
            <template v-slot:activator>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-list-item
              :key="i"
              link
              v-for="(child, i) in item.children"
            >
              <v-list-item-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ child.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item
            :key="item.text"
            :to="item.route"
            link
            v-else
          >
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <template v-slot:append>
        <v-divider></v-divider>
        <div class="py-2 px-2 d-flex">
          <v-menu></v-menu>
          <v-btn text>
            {{version}}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="safetyDialog = true"
          >
            <v-icon>mdi-help-circle</v-icon>
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-main>
      <v-dialog v-model="safetyDialog" persistent max-width="370">
        <v-card>
          <v-card-title class="headline">{{$t('safety_tips_title')}}</v-card-title>
          <v-card-text v-if="isCsAgent">
            <ul>
              <li>{{$t('safety_tips_1')}}</li>
              <li>{{$t('safety_tips_2')}}</li>
            </ul>
          </v-card-text>
          <v-card-text v-else>
            <ul>
              <li>{{$t('safety_tips_1')}}</li>
            </ul>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="safetyDialog = false">{{$t('agree')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-container class="pb-0">
        <v-alert
          prominent
          v-if="(isCsAgent || isCsAgentBot ) && !workingMode && autoAssignMode"
          type="error"
          class="mb-0"
        >
          <v-row align="center">
            <v-col class="grow">
              {{$t('warning_working_mode')}}
            </v-col>
            <v-col class="shrink">
              <v-btn light @click="setWorkingMode(true)">{{$t('accept_tasks')}}</v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-container>

      <router-view></router-view>
<!--      wMode: {{workingMode}}<br/>-->
<!--      wmValue: {{wmValue}}<br/>-->
<!--      wmIv: {{wmIv}}<br/>-->
<!--      <div v-for="(item, index) in notifications" :key="index">-->
<!--        {{ item }}-->
<!--      </div>-->
    </v-main>

    <v-snackbar
      v-model="soundSnackbar"
      top
      :timeout="-1"
    >
      {{ $t('play_sound_req') }}
      <v-btn
        text
        @click="allowAudio"
      >
        {{$t('allow')}}
      </v-btn>
      <v-btn
        icon
        @click="soundSnackbar = false"
      >
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
  </span>
</template>

<script>
  import { mapGetters } from 'vuex'
  import Loading from '../components/Loading'
  import * as supportedLocales from '../plugins/i18n/supported-locales'
  import globalMixins from '../mixins/globalMixins'
  import transactionMixins from '../mixins/transactionMixins'

  export default {
    name: 'AuthenticatedLayout',
    metaInfo () {
      return {
        title: '',
        titleTemplate: this.notification + ' %s ' + this.appName,
      }
    },
    mixins: [globalMixins, transactionMixins],
    mounted () {
      // this.testAudio();
      this.connect();
      this.$loading = this.$refs.loading;
    },
    components: {
      Loading,
    },
    computed: {
      ...mapGetters({
        locale: 'lang/locale',
        user: 'auth/user',
        safetyDialog: 'auth/safetyDialog',
        autoAssignMode: 'auth/autoAssignMode',
        topUpAssignment: 'auth/topUpAssignment',
        withdrawalAssignment: 'auth/withdrawalAssignment',
        unassignedTopUpTasks: 'auth/unassignedTopUpTasks',
        unassignedWithdrawalTasks: 'auth/unassignedWithdrawalTasks',
        authenticated: 'auth/check',
        snackbarColor: 'snackbar/color',
        snackbarMsg: 'snackbar/message',
        isRoot: 'auth/isRoot',
        isManager: 'auth/isManager',
        isCsAgent: 'auth/isCsAgent',
        isCsAgentBot: 'auth/isCsAgentBot',
        isReporter: 'auth/isReporter',
        isClient: 'auth/isClient',
        isClientCsa: 'auth/isClientCsa',
        isSysErrLogMute: 'auth/isSysErrLogMute',
        hasNewSysErrLog: 'auth/hasNewSysErrLog',
      }),
      notificationCount() {
        return this.topUpAssignment.length + this.withdrawalAssignment.length;
      },
      notification() {
        const length = this.notificationCount;
        return length > 0 ? '(' + this.formatLength(length, this.maxNotification) + ')' : '';
      },
      pendingTopUpCount() {
        const length = this.topUpAssignment.length;
        return length ? '(' + this.formatLength(length, this.maxNotification) + ')' : '';
      },
      pendingWithdrawalCount() {
        const length = this.withdrawalAssignment.length;
        return length ? '(' + this.formatLength(length, this.maxNotification) + ')' : '';
      },
      unassignedTopUpCount() {
        const length = this.unassignedTopUpTasks.length;
        return length ? '(' + this.formatLength(length, this.maxNotification) + ')' : '';
      },
      unassignedWithdrawalCount() {
        const length = this.unassignedWithdrawalTasks.length;
        return length ? '(' + this.formatLength(length, this.maxNotification) + ')' : '';
      },
      workingMode: {
        get() {
          return this.$store.state.auth.workingMode;
        },
        set(val) {
          this.setWorkingMode(val)
        }
      },
      safetyDialog: {
        get() {
          return this.$store.state.auth.safetyDialog;
        },
        set(val) {
          this.$store.dispatch('auth/updateSafetyDialog', val)
        }
      },
      items () {
        if (this.isRoot) {
          return [
            {
              icon: 'mdi-cog',
              text: this.$t('service_provider'),
              route: {name: 'serviceProvider'}
            },
            {
              icon: 'mdi-bank',
              text: this.$t('bank'),
              route: {name: 'bank'}
            },
            {
              icon: 'mdi-credit-card-outline',
              text: this.$t('bank_card'),
              route: {name: 'bankCard.list'}
            },
            {
              icon: 'mdi-contacts',
              text: this.$t('contact'),
              route: {name: 'contact.client'}
            },
            {
              icon: 'mdi-history',
              text: this.$t('balance_history'),
              route: {name: 'balanceHistory'}
            },
            {
              icon: 'mdi-database-arrow-left',
              text: this.$t('top_up_transaction_record') + ' ' + this.pendingTopUpCount,
              route: {name: 'task', params: { status: 'pending', type: 'topup' }}
            },
            {
              icon: 'mdi-database-arrow-right',
              text: this.$t('withdrawal_transaction_record') + ' ' + this.pendingWithdrawalCount,
              route: {name: 'task', params: { status: 'pending', type: 'withdrawal' }}
            },
            {
              icon: 'mdi-database-edit',
              text: this.$t('adjust_transaction_record'),
              route: {name: 'task', params: { status: 'complete', type: 'adjust' }}
            },
            {
              icon: 'mdi-database-arrow-left',
              text: this.$t('unassigned_topup_transaction') + ' ' + this.unassignedTopUpCount,
              route: {name: 'task', params: { status: 'unassigned', type: 'topup' }}
            },
            {
              icon: 'mdi-database-arrow-right',
              text: this.$t('unassigned_withdrawal_transaction') + ' ' + this.unassignedWithdrawalCount,
              route: {name: 'task', params: { status: 'unassigned', type: 'withdrawal' }}
            },
            {
              icon: 'mdi-car-brake-alert',
              text: this.$t('sys_err_log') + ' ' + (this.hasNewSysErrLog ? ' (!)' : ''),
              route: {name: 'systemErrorLog'},
            },
            {
              icon: 'mdi-file-sign',
              text: this.$t('playground_signature'),
              route: {name: 'playgroundSignature'}
            },
          ]
        } else if (this.isManager) {
          return [
            {
              icon: 'mdi-cog',
              text: this.$t('service_provider'),
              route: {name: 'serviceProvider'}
            },
            {
              icon: 'mdi-credit-card-outline',
              text: this.$t('bank_card'),
              route: {name: 'bankCard.list'}
            },
            {
              icon: 'mdi-contacts',
              text: this.$t('contact'),
              route: {name: 'contact.client'}
            },
            {
              icon: 'mdi-history',
              text: this.$t('balance_history'),
              route: {name: 'balanceHistory'}
            },
            {
              icon: 'mdi-database-arrow-left',
              text: this.$t('top_up_transaction_record') + ' ' + this.pendingTopUpCount,
              route: {name: 'task', params: { status: 'pending', type: 'topup' }}
            },
            {
              icon: 'mdi-database-arrow-right',
              text: this.$t('withdrawal_transaction_record') + ' ' + this.pendingWithdrawalCount,
              route: {name: 'task', params: { status: 'pending', type: 'withdrawal' }}
            },
            {
              icon: 'mdi-database-edit',
              text: this.$t('adjust_transaction_record'),
              route: {name: 'task', params: { status: 'complete', type: 'adjust' }}
            },
            {
              icon: 'mdi-database-arrow-left',
              text: this.$t('unassigned_topup_transaction') + ' ' + this.unassignedTopUpCount,
              route: {name: 'task', params: { status: 'unassigned', type: 'topup' }}
            },
            {
              icon: 'mdi-database-arrow-right',
              text: this.$t('unassigned_withdrawal_transaction') + ' ' + this.unassignedWithdrawalCount,
              route: {name: 'task', params: { status: 'unassigned', type: 'withdrawal' }}
            },
            {
              icon: 'mdi-car-brake-alert',
              text: this.$t('sys_err_log') + ' ' + (this.hasNewSysErrLog ? ' (!)' : ''),
              route: {name: 'systemErrorLog'},
            },
          ]
        } else if (this.isCsAgent) {
          return [
            {
              icon: 'mdi-calendar-check',
              text: this.$t('top_up_task') + ' ' + this.pendingTopUpCount,
              route: {name: 'task', params: { status: 'pending', type: 'topup' }}
            },
            {
              icon: 'mdi-calendar-check',
              text: this.$t('withdrawal_task') + ' ' + this.pendingWithdrawalCount,
              route: {name: 'task', params: { status: 'pending', type: 'withdrawal' }}
            },
            {
              icon: 'mdi-database-arrow-left',
              text: this.$t('unassigned_topup_transaction') + ' ' + this.unassignedTopUpCount,
              route: {name: 'task', params: { status: 'unassigned', type: 'topup' }}
            },
            {
              icon: 'mdi-database-arrow-right',
              text: this.$t('unassigned_withdrawal_transaction') + ' ' + this.unassignedWithdrawalCount,
              route: {name: 'task', params: { status: 'unassigned', type: 'withdrawal' }}
            },
          ]
        } else if (this.isCsAgentBot) {
          return [
            {
              icon: 'mdi-calendar-check',
              text: this.$t('auto_top_up_task') + ' ' + this.pendingTopUpCount,
              route: {name: 'task', params: { status: 'pending', type: 'topup' }}
            },
            {
              icon: 'mdi-database-arrow-left',
              text: this.$t('unassigned_auto_topup_transaction') + ' ' + this.unassignedTopUpCount,
              route: {name: 'task', params: { status: 'unassigned', type: 'topup' }}
            },
          ]
        } else if (this.isReporter) {
          return [
            {
              icon: 'mdi-database-arrow-left',
              text: this.$t('top_up_transaction_record') + ' ' + this.pendingTopUpCount,
              route: {name: 'task', params: { status: 'pending', type: 'topup' }}
            },
            {
              icon: 'mdi-database-arrow-right',
              text: this.$t('withdrawal_transaction_record') + ' ' + this.pendingWithdrawalCount,
              route: {name: 'task', params: { status: 'pending', type: 'withdrawal' }}
            },
            {
              icon: 'mdi-database-edit',
              text: this.$t('adjust_transaction_record'),
              route: {name: 'task', params: { status: 'complete', type: 'adjust' }}
            },
          ]
        } else if (this.isClient) {
          return [
            {
              icon: 'mdi-database-arrow-left',
              text: this.$t('top_up_transaction_record') + ' ' + this.pendingTopUpCount,
              route: {name: 'client.customerTransaction', params: { status: 'complete', type: 'topup' }}
            },
            {
              icon: 'mdi-database-arrow-right',
              text: this.$t('withdrawal_transaction_record') + ' ' + this.pendingWithdrawalCount,
              route: {name: 'client.customerTransaction', params: { status: 'complete', type: 'withdrawal' }}
            },
            {
              icon: 'mdi-database-edit',
              text: this.$t('adjust_transaction_record'),
              route: {name: 'client.customerTransaction', params: { status: 'complete', type: 'adjust' }}
            },
            {
              icon: 'mdi-transfer-down',
              text: this.$t('withdrawal'),
              route: {name: 'client.withdrawal'}
            },
            {
              icon: 'mdi-close-network',
              text: this.$t('blacklist_ip'),
              route: {name: 'client.blacklistIps'}
            },
            {
              icon: 'mdi-history',
              text: this.$t('balance_history'),
              route: {name: 'client.balanceHistory'}
            },
          ]
        } else if (this.isClientCsa) {
          return [
            {
              icon: 'mdi-database-arrow-left',
              text: this.$t('top_up_transaction_record') + ' ' + this.pendingTopUpCount,
              route: {name: 'clientCsa.customerTransaction', params: { status: 'complete', type: 'topup' }}
            },
            {
              icon: 'mdi-database-arrow-right',
              text: this.$t('withdrawal_transaction_record') + ' ' + this.pendingWithdrawalCount,
              route: {name: 'clientCsa.customerTransaction', params: { status: 'complete', type: 'withdrawal' }}
            },
            {
              icon: 'mdi-database-edit',
              text: this.$t('adjust_transaction_record'),
              route: {name: 'clientCsa.customerTransaction', params: { status: 'complete', type: 'adjust' }}
            },
            {
              icon: 'mdi-history',
              text: this.$t('balance_history'),
              route: {name: 'clientCsa.balanceHistory'}
            },
          ]
        } else {
          return [
            {
              icon: 'mdi-login',
              text: this.$t('sign_in'),
              route: {name: 'signin'}
            },
          ]
        }
      },
      account_items () {
        return [
          {
            icon: 'mdi-account-circle',
            href: '#',
            title: this.$t('profile'),
            action: 'profile'
          },
          {
            icon: 'mdi-logout',
            href: '#',
            title: this.$t('logout'),
            action: 'logout'
          }
        ]
      },
      disp_balance () {

        // Display user balance if exist, else for client CS display the client
        // balance

        const user = this.$store.state.auth.user;

        if (user) {
          if (user.balance) {
            return user.balance;
          }
          else if (user.client_balance) {
            return user.client_balance;
          }
        }

        return null;
      }
    },
    data () {
      return {
        languages: supportedLocales.locales,
        switchBusy: null,
        wmValue: true,
        wmIv: true,
        dialog: false,
        drawer: null,
        busy: false,
        notifications: [],
        version: 'v' + process.env.VUE_APP_VERSION,
        soundSnackbar: false,
        audio: new Audio('/sound/inflicted.ogg'),
        sys_err_log_audio: new Audio('/sound/sys_err_log_alert.ogg'),
        maxNotification: 50,
      }
    },
    methods: {
      setWorkingMode (val) {
        this.$store.dispatch('auth/updateWorkingMode', val)
      },
      localeItemClicked (locale) {
        if (this.$i18n.locale !== locale) {
          // loadMessages(locale)
          this.$store.dispatch('lang/setLocale', locale)
        }
      },
      accountMenuItemClicked (action) {
        switch (action) {
          case 'profile':
            this.$router.push({name: 'settings.password'});
            break;
          case 'logout':
            this.logout();
            break;
        }
      },
      async logout () {
        await this.$store.dispatch('auth/logout')
        this.$router.push({name: 'home'}).catch(err => err)
      },
      connect () {
        this.socket = new WebSocket(this.wsEndpoint);
        let _this = this
        this.socket.onopen = function () {
          this.status = "connected";
          // eslint-disable-next-line no-console
          // console.log("connected")

          // _this.notifications.push({ event: "Connected to", data: '/app/ws'})
        };

        this.socket.onmessage = function (response) {
          const data = JSON.parse(response.data)
          // _this.notifications.push({ event: "Recieved message", data: response.data });

          // eslint-disable-next-line no-console
          // console.log("received")

          // console.log(data); //eslint-disable-line
          if (data.target === 'transaction') {
            switch (data.action) {
              case 'add':
                _this.audio.play();
                if (data.data.transaction_type === _this.TransactionType.ADJUSTMENT) {
                  break;
                } else if (_this.isRoot || _this.isManager) {
                  if (!data.data.bank.bank || !data.data.beneficiary_name) { // partial order
                    _this.$store.dispatch('auth/addAssignment', data.data);
                  } else { //full order
                    if (data.data.assignee) { // assigned
                      _this.$store.dispatch('auth/addAssignment', data.data);
                    } else { //unassigned
                      _this.$store.dispatch('auth/addUnassignedTask', data.data);
                    }
                  }
                } else if (_this.isClient || _this.isClientCsa) {
                  _this.$store.dispatch('auth/addAssignment', data.data);
                } else if (_this.isCsAgent || _this.isCsAgentBot) {
                  //only receive full orders
                  if (data.data.assignee) { // assigned
                    _this.$store.dispatch('auth/addAssignment', data.data);
                  } else { //unassigned
                    _this.$store.dispatch('auth/addUnassignedTask', data.data);
                  }
                }
                break;
              case 'update':
                _this.audio.play();
                if (_this.isRoot || _this.isManager) {
                  if (!data.data.bank.bank || !data.data.beneficiary_name) { // partial order
                  } else { //full order
                    _this.$store.dispatch('auth/removeAssignment', data.data.transaction_number);
                    if (data.data.assignee) { // assigned
                      _this.$store.dispatch('auth/addAssignment', data.data);
                    } else { //unassigned
                      _this.$store.dispatch('auth/addUnassignedTask', data.data);
                    }
                  }
                } else if (_this.isClient || _this.isClientCsa) {
                  _this.$store.dispatch('auth/removeAssignment', data.data.transaction_number);
                  _this.$store.dispatch('auth/addAssignment', data.data);
                } else if (_this.isCsAgent || _this.isCsAgentBot) {
                  if (data.data.assignee) { // assigned
                    //only receive full orders
                    if (data.data.assignee) { // assigned
                      _this.$store.dispatch('auth/removeUnassignedTask', data.data.transaction_number);
                    }
                  }
                  _this.$store.dispatch('auth/updateEditingTask', data.data);
                  // {"target":"transaction","action":"update","data":{"transaction_number":588,"amount":311.97,"net_amount":null,"currency_code":"CNY","transaction_type":2,"client_id":241,"cs_agent_id":null,"transaction_status":0,"service_provider_id":0,"creation_time":1653663368119,"completion_time":null,"transaction_method":1,"client_name":"client name","client_order_id":"31004","client_user_id":"1","remark":null,"bank":{"bank":"-","country":"","province":"","city":"","branch":""},"paytype":"remit","beneficiary_account":"","beneficiary_name":"-","card":{},"collectb_sts":3,"assignee":316}}
                }
                break;
              case 'delete':
                break;
              default:
                break;
            }
          }
          else if (data.target === 'sys_err_log') {
            switch (data.action) {
              case 'add':
                _this.$store.dispatch('auth/setSysErrLogNewItem', true);
                
                if (!_this.isSysErrLogMute) {
                  _this.sys_err_log_audio.play();
                }

                break;
              default:
                break;
            }
          }
          return false;
        };

        this.socket.onclose = function () {
          // eslint-disable-next-line no-console
          // console.log("close")

          // Try to reconnect in 5 seconds
          setTimeout(function(){
            // if (_this.authenticated) {
              _this.$store.dispatch('auth/fetchAssignment')
              _this.connect()
            // }
          }, 5000);
        };
      },
      disconnect () {
        this.socket.close();
        this.status = "disconnected";
        this.notifications = [];
      },
      refreshUser () {
        this.$store.dispatch('auth/fetchUser');
      },
      testAudio () {
        this.audio.muted = true;
        this.audio.play().then( function () {
          // already allowed
        }).catch( function () {
          // need user interaction
          this.soundSnackbar = true;
        }.bind(this));
      },
      allowAudio () {
        this.audio.play().then( function () {
          this.resetAudio();
          this.soundSnackbar = false;
        }.bind(this));
      },
      resetAudio() {
        this.audio.pause();
        this.audio.currentTime = 0;
        this.audio.muted = false;
      },
      formatLength (length, max = 50) {
        return length > max ? max + '+' : length;
      }
    },
  };
</script>

<style>
  .theme--dark.working .v-label {
    font-size: 0.875rem;
    color: #FFFFFF;
  }
  .bold-uppercase{
    font-size: 0.875rem;
    font-weight: 500;
    text-transform: uppercase
  }
</style>
